/* eslint-disable no-void */
/* eslint-disable block-scoped-var */
/* eslint-disable eqeqeq */
/* eslint-disable no-plusplus */
/* eslint-disable vars-on-top */
/* eslint-disable one-var */
/* eslint-disable no-sequences */
/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-var */
/* eslint-disable yoda */
/* eslint-disable object-shorthand */
/* eslint-disable no-underscore-dangle */
/* eslint-disable func-names */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
const onLoad = () => {
  !(function () {
    window._klOnsite = window._klOnsite || [];
    try {
      window.klaviyo = new Proxy(
        {},
        {
          get: function (n, i) {
            return 'push' === i
              ? function () {
                  var n;
                  (n = window._klOnsite).push.apply(n, arguments);
                }
              : function () {
                  for (
                    var n = arguments.length, o = new Array(n), w = 0;
                    w < n;
                    w++
                  )
                    o[w] = arguments[w];
                  var t =
                      'function' == typeof o[o.length - 1] ? o.pop() : void 0,
                    e = new Promise(function (n) {
                      window._klOnsite.push(
                        [i].concat(o, [
                          function (i) {
                            t && t(i), n(i);
                          },
                        ])
                      );
                    });
                  return e;
                };
          },
        }
      );
    } catch (n) {
      (window.klaviyo = window.klaviyo || []),
        (window.klaviyo.push = function () {
          var n;
          (n = window._klOnsite).push.apply(n, arguments);
        });
    }
  })();
};

export default onLoad;
