import { Experiment } from '@amplitude/experiment-js-client';
import { useEffect, useState } from 'react';

const useExperiment = (experimentName: string): string => {
  const amplitudeId = process.env.NEXT_PUBLIC_AMPLITUDE_ID;
  const [variant, setVariant] = useState(null);

  useEffect(() => {
    const initExperiment = async (): Promise<void> => {
      const experiment =
        Experiment.initializeWithAmplitudeAnalytics(amplitudeId);

      await experiment.start();

      const variantObj = experiment.variant(experimentName);

      if (variantObj && variantObj.value) {
        setVariant(variantObj.value);
      }
    };

    if (amplitudeId) {
      initExperiment();
    }
  }, [amplitudeId, experimentName]);

  return variant;
};

export default useExperiment;
